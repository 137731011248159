<template>
  <v-container>
    <v-row v-if="category">
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>Update Category</v-card-title>
          <v-card-text>
            <v-text-field v-model="category.name"></v-text-field>
            <v-textarea v-model="category.description"></v-textarea>
          </v-card-text>
        </v-card>
        <v-col>
          <v-toolbar flat dark color="purple darken-2" extended></v-toolbar>
          <v-card v-if="isFeatureUpdate" class="mx-auto" max-width="500" style="margin-top: -64px;">
            <v-toolbar flat>
              <v-toolbar-title>Features</v-toolbar-title>
              <div class="flex-grow-1"></div>
              <v-btn @click="cancelFeature()" text color="success">Cancel</v-btn>
              <v-btn @click="saveFeature()" text color="success">Save</v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                outlined
                :rules="[rules.required]"
                v-model="feature.name"
                label="Name of Feature"
              ></v-text-field>
              <!-- Combobox Start -->
              <v-combobox
                :rules="[rules.required]"
                outlined
                v-model="feature.options"
                :items="feature.options"
                :search-input.sync="search"
                hide-selected
                label="Add some tags"
                multiple
                persistent-hint
                small-chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "
                        <strong>{{ search }}</strong>". Press
                        <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-card-text>
          </v-card>
          <v-card v-else class="mx-auto" max-width="500" style="margin-top: -64px;">
            <v-toolbar flat>
              <v-toolbar-title>Features</v-toolbar-title>
              <div class="flex-grow-1"></div>
              <v-btn @click="isFeatureUpdate = true" icon>
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-list>
                <v-list-item v-for="(item, index) in category.features" :key="index">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn @click="editFeature(item, index)" icon color="success">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-icon>
                    <v-btn @click="deleteFeature(index)" icon color="error">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-text>
            <v-row justify="end">
              <v-col cols="4">
                <v-btn to="/admin/products/category/list" text color="error">Cancel</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn outlined @click="updateCategory()" color="success">Update</v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col cols="8">
                <v-file-input
                  show-size
                  v-model="images"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Select images"
                  prepend-icon="mdi-camera"
                  label="Images"
                  outlined
                  color="primary"
                ></v-file-input>
                <!-- <span v-if="progress != null">{{progress()}}</span> -->
              </v-col>
              <v-col cols="4">
                <v-btn
                  color="primary"
                  height="56"
                  v-if="progress == 0"
                  block
                  large
                  outlined
                  @click="uploadCategoryImage()"
                >Upload</v-btn>
                <v-progress-circular
                  v-else
                  size="56"
                  color="primary"
                  v-model="progress"
                >{{ parseInt(progress) }}</v-progress-circular>
              </v-col>
              <v-col cols="12">
                <v-img v-if="category.image" :src="category.image">
                  <v-icon small color="primary" @click="deleteImage(category.image)">clear</v-icon>
                </v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <Snackbar :snackbar="snackbar" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import firebase from "firebase";

export default {
  data() {
    return {
      id: this.$route.params.id,
      // Combobox Data
      search: null,
      // Active Features Update or add mode
      isFeatureUpdate: false,
      // Form Data
      rules: {
        required: value => !!value || "Required."
      },
      feature: {
        name: "",
        options: []
      },
      featureIndex: null,

      images: null,
      progress: 0,
      snackbar: {
        isActive: false, //Activator
        x: "", // left or right or ""
        y: "", // top or bottom or ""
        color: "", // Any Color or ""
        mode: null, //vertical or multi-line or null
        // timeout: null, // set timeout Ex: 60000
        text: "" //Error Message
      }
    };
  },
  computed: {
    ...mapGetters(["allProductCategories"]),
    category() {
      const category = this.allProductCategories.filter(
        category => category.id == this.id
      );
      return category[0];
    }
  },
  methods: {
    ...mapActions(["update", "bind"]),
    saveFeature() {
      if (this.feature.name !== "" && this.feature.options.length != 0) {
        if (this.featureIndex === null) {
          this.category.features.push(this.feature);
        } else {
          this.category.features[this.featureIndex] = this.feature;
        }
        this.feature = {
          name: "",
          options: []
        };
        this.featureIndex = null;
        this.isFeatureUpdate = false;
      } else {
        this.error = true;
      }
    },
    cancelFeature() {
      this.feature = {
        name: "",
        options: []
      };
      this.featureIndex = null;
      this.isFeatureUpdate = false;
    },
    // Delete Feature to Data (category.feature )
    deleteFeature(index) {
      this.category.features.splice(index, 1);
    },
    // Edit Feature to Data (category.feature )
    editFeature(item, index) {
      this.feature = item;
      this.featureIndex = index;
      this.isFeatureUpdate = true;
    },
    updateCategory() {
      this.update({
        collection: "productCategories",
        doc: this.id,
        data: this.category
      }).then(() => {
        this.$router.replace("/admin/products/category/list");
      });
    },
    fetchCategory() {
      if (!this.allProductCategories.length) {
        this.bind({
          collection: "productCategories",
          data: "productCategories"
        });
      }
    },
    uploadCategoryImage() {
      if (this.images) {
        if (this.category.image) {
          this.deleteImage(this.category.image);
          this.uploadImage();
        } else {
          this.uploadImage();
        }
      } else {
        this.snackbar.text = "Please select an image";
        this.snackbar.color = "warning";
        this.snackbar.isActive = true;
      }
    },

    uploadImage() {
      if (this.images) {
        // console.log(image.target.files[0])
        let file = this.images;
        let time = Date.now();
        // File or Blob named mountains.jpg

        let storageRef = firebase
          .storage()
          .ref("product_categories/" + time + "_" + file.name);

        // Create the file metadata
        let metadata = {
          contentType: "image/jpeg"
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        let uploadTask = storageRef.put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          snapshot => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            // console.log("Upload is " + this.progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log("Upload is running");
                break;
            }
          },
          error => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              // console.log('File available at', downloadURL);
              this.progress = 0;
              this.images = null;
              this.category.image = downloadURL;
              // Update product images
              this.update({
                collection: "productCategories",
                doc: this.id,
                data: {
                  image: this.category.image
                }
              });
              this.snackbar.text = "Uploaded successfully";
              this.snackbar.color = "success";
              this.snackbar.isActive = true;
            });
          }
        );
      } else {
        this.snackbar.text = "Please select an image";
        this.snackbar.color = "warning";
        this.snackbar.isActive = true;
      }
    },
    // Delete image function
    deleteImage(imageUrl) {
      // Get Image URL and Reff in firebase storage
      let image = firebase.storage().refFromURL(imageUrl);
      // Delete the file from Firebase Storage
      image
        .delete()
        .then(() => {
          // console.log("File deleted successfully");
          this.category.image = null; // Remove from Data
          // Update image
          this.update({
            collection: "productCategories",
            doc: this.id,
            data: {
              image: this.category.image
            }
          });
          this.snackbar.text = "Image deleted successfully";
          this.snackbar.color = "success";
          this.snackbar.isActive = true;
        })
        .catch(error => {
          this.snackbar.text = "Uh-oh, an error occurred!";
          this.snackbar.color = "error";
          this.snackbar.isActive = true;
          // console.log("Uh-oh, an error occurred!");
        });
    }
  },
  created() {
    this.fetchCategory();
  }
};
</script>

<style></style>
